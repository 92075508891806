import { createBrowserRouter, useLocation } from "react-router-dom";
import HomePage from "@/src/pages/page";
import NotFound from "@/src/components/not-found";
import ContactPage from "@/src/pages/contact/page";
import Nav from "@/src/components/nav";
import { ReactElement, cloneElement, useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Footer from "@/src/components/footer";
import MindsPage from "@/src/pages/minds/page";
// import NewsPage from "./pages/news/page";
import InvitationPage from "./pages/invitation/page";
import SustainabilityPage from "./pages/sustainability/page";
import JoinPage from "./pages/join/page";
import ProjectPage from "./pages/projects/[id]/page";
import ProjectsPage from "./pages/projects/page";
import { cn } from "./utils/utils";

const PageContainer = (props: {
  children: ReactElement;
  includeNav: boolean;
  includeFooter: boolean;
  snap?: boolean;
}) => {
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [yScroll, setYScroll] = useState(0);
  const [yScrollProgress, setYScrollProgress] = useState(0);

  useEffect(() => {
    setYScroll(0);
    setYScrollProgress(0);
  }, [location.pathname]);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        ref={containerRef}
        key={location.pathname}
        id="main-container"
        className={
          cn(
            "h-dvh hide-scrollbar hide-main-scrollbar overflow-y-scroll overflow-x-hidden",
            props.snap ? "snap-y snap-mandatory" : "",
          )
        }
        onScroll={() => {
          if (!containerRef.current) return;
          setYScroll(containerRef.current.scrollTop);
          setYScrollProgress(
            containerRef.current.scrollTop / containerRef.current.scrollHeight
          );
        }}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        {props.includeNav && <Nav />}
        {cloneElement(props.children, {
          yScroll: yScroll,
          yScrollProgress: yScrollProgress,
        })}
        {props.includeFooter && <Footer />}
      </motion.div>
    </AnimatePresence>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PageContainer
        includeNav={true}
        includeFooter={false}
        children={<HomePage />}
        snap={true}
      />
    ),
    children: [],
  },
  {
    path: "minds",
    element: (
      <PageContainer
        includeNav={true}
        includeFooter={true}
        children={<MindsPage />}
      />
    ),
  },
  {
    path: "projects",
    element: (
      <PageContainer
        children={<ProjectsPage />}
        includeNav={true}
        includeFooter={true}
      />
    ),
  },
  {
    path: "project/:id",
    element: (
      <PageContainer
        children={<ProjectPage />}
        includeNav={true}
        includeFooter={true}
      />
    ),
  },
  // {
  //   path: "news",
  //   element: (
  //     <PageContainer
  //       includeNav={true}
  //       includeFooter={true}
  //       children={<NewsPage />}
  //     />
  //   ),
  // },
  {
    path: "contact",
    element: (
      <PageContainer
        children={<ContactPage />}
        includeNav={true}
        includeFooter={true}
      />
    ),
  },
  {
    path: "sustainability",
    element: (
      <PageContainer
        children={<SustainabilityPage />}
        includeNav={true}
        includeFooter={true}
      />
    ),
  },
  {
    path: "join",
    element: (
      <PageContainer
        children={<JoinPage />}
        includeNav={true}
        includeFooter={true}
      />
    ),
  },
  {
    path: "invitation/:name",
    element: <InvitationPage />,
  },
  {
    path: "*",
    element: (
      <PageContainer
        children={<NotFound />}
        includeNav={true}
        includeFooter={true}
      />
    ),
  },
]);

export default router;
