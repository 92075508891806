import { Button } from "@/src/components/ui/button";
import { Input } from "@/src/components/ui/input";
import { Textarea } from "@/src/components/ui/textarea";
import { toast } from "react-toastify";

function ContactPage() {
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    try {
      e.preventDefault();
      const target: any = e.target;
      if (!target.name.value || !target.email.value) {
        toast.error("Please fill out all required fields");
        return;
      }
      const data = {
        name: target.name.value,
        email: target.email.value,
        message: target.message.value,
      };
      const response = await fetch(
        "https://rmgmrf2nrr5jxquanwgto7b2t40djmuy.lambda-url.ap-northeast-1.on.aws/",
        {
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors",
          method: "POST",
        }
      );
      if (response.ok) {
        toast("Message sent", { type: "success" });
      } else {
        toast("Failed to send message", { type: "error" });
      }
    } catch (e: any) {
      toast("Failed to send message", { type: "error" });
    }
  }

  return (
    <div className="py-24 px-2 md:px-[10%] flex items-center justify-center">
      <div className="container flex flex-col justify-center gap-2 relative">
        <h1 className="text-2xl text-black font-medium block md:absolute top-0 left-0">STUDIOS</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-16">
          <div className="flex flex-col gap-2">
            <div className="relative group aspect-square max-w-80 md:max-w-none border border-gray-300 rounded-sm overflow-hidden">
              <img className="rounded-sm w-full h-full object-cover" src="images/contact/hk_office_2.jpg" alt="hk_office_2" />
              <div className="absolute inset-0 bg-white/40 backdrop-blur-sm group-hover:opacity-0 transition-opacity duration-300"></div>
            </div>
            <div>
              <div className="text-xl font-bold mt-2">Kwun Tong</div>
              <div className="text-sm mt-2">Workshop D23, 13/F, Manning Industrial Building, 116-118 How Ming Street, Kwun Tong, Hong Kong</div>
              <div className="text-sm">bonjour@tonsofminds.com</div>
              <div className="text-sm">+852 67610002</div>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="relative group aspect-square max-w-80 md:max-w-none border border-gray-300 rounded-sm overflow-hidden">
              <img className="rounded-sm w-full h-full object-cover" src="images/contact/hk_office_1.jpg" alt="hk_office_1" />
              <div className="absolute inset-0 bg-white/40 backdrop-blur-sm group-hover:opacity-0 transition-opacity duration-300"></div>
            </div>
            <div>
              <div className="text-xl font-bold mt-2">San Po Kong</div>
              <div className="text-sm mt-2">Unit 7, 1/F, 7 Sheung Hei Street, Kowloon, Hong Kong</div>
              <div className="text-sm">bonjour@tonsofminds.com</div>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="relative group aspect-square max-w-80 md:max-w-none border border-gray-300 rounded-sm overflow-hidden">
              <img className="rounded-sm w-full h-full object-cover" src="images/contact/sz_office_1.jpg" alt="sz_office_1" />
              <div className="absolute inset-0 bg-white/40 backdrop-blur-sm group-hover:opacity-0 transition-opacity duration-300"></div>
            </div>
            <div>
              <div className="text-xl font-bold mt-2">Shenzhen Office 深圳前海办公室</div>
              <div></div>
              <div className="text-sm mt-2">深圳市前海深港合作区海运中心主塔楼12楼038室</div>
              <div className="text-sm">shenzhen@tonsofminds.com</div>
              <div className="text-sm">+86 15960206535</div>
            </div>
          </div>
        </div>

        <hr className="w-full my-12" />
        <form className="flex flex-col md:flex-row w-full md:[&>*]:w-1/2 my-2" onSubmit={(e) => handleSubmit(e)}>
          <h2 className="text-2xl text-black">CONTACT US</h2>
          <div>
            <Input type="text" name="name" maxLength={100} placeholder="Name*" required />
            <Input type="email" name="email" maxLength={100} placeholder="Email*" required />
            <Textarea name="message" maxLength={1000} placeholder="Message" />
            <Button type="submit" variant={"default"} className="w-24 h-12 text-lg md:text-xl bg-foreground hover:bg-transparent hover:text-black hover:border-gray-300 mt-2">SEND</Button>
          </div>
        </form>
      </div>
    </div>
);

}

export default ContactPage;