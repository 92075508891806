"use client"

import { Link } from "react-router-dom";

const posts = [
  {
    category: "Architecture",
    post: <>Internship - Computational Design<br />Hong Kong</>,
  },
  {
    category: "Architecture",
    post: <>Design Lead - Architecture<br />Hong Kong</>,
  },
  {
    category: "Art",
    post: <>3D & Metaverse Artist<br />Hong Kong</>,
  },
  {
    category: "Information Technology",
    post: <>Software Engineer<br />Hong Kong</>,
  },
  {
    category: "Product Design",
    post: <>Healthcare Product Designer<br />Shenzhen</>,
  },
  {
    category: "Trading",
    post: <>Project Manager, Organic Food<br />Shenzhen</>,
  },
  {
    category: "Marketing & Communications",
    post: <>Communications Manager<br />Shenzhen</>,
  },
]

function JoinPage(props: {
  yScroll?: number;
}) {
  return (
    <div className="full-screen py-24 px-2 md:px-[10%] flex items-center justify-center">
      <div className="container flex flex-col justify-center gap-2 relative">
        <h1 className="text-2xl text-black font-medium mb-2">JOIN US</h1>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div>
            <div className="bg-[#00FD89] w-full h-1" />
            <div className="px-2 mt-2">
              <div className="font-bold">Become a Member</div>
              <div>Start your journey with Tons of Minds from $50, directly supporting our artistic endeavors and enjoying member benefits.</div>
            </div>
          </div>
          <div>
            <div className="bg-[#26B4E1] w-full h-1" />
            <div className="px-2 mt-2">
              <div className="font-bold">Become a Corporate Member</div>
              <div>Align your business with the art, supporting our mission while enjoying unique art and tech transformation opportunities.</div>
            </div>
          </div>
          <div>
            <div className="bg-[#AB30F0] w-full h-1" />
            <div className="px-2 mt-2">
              <div className="font-bold">Make a Donation</div>
              <div>Fuel the transformative power of art with your generous contribution to our work.</div>
            </div>
          </div>
        </div>
        <div className="mt-12">
          <hr className="my-2" />
          <div className="mt-4 text-2xl text-black">CAREERS</div>
          <div className="mt-4">Search our current opportunities around the world.</div>
          <div className="w-full mt-8 [&>tr]:border-0">
            {
              posts.map((post, index) => (
                <div className="grid gap-2 grid-cols-1 md:grid-cols-3 p-2" key={`post-${index}`}>
                  <div className="font-bold">{post.category}</div>
                  <div>{post.post}</div>
                  <div><Link to={"mailto:hr@tonsofminds.com"}>Apply</Link></div>
                  <hr className="w-full md:col-span-3" />
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinPage;
