import { EnvelopeClosedIcon, InstagramLogoIcon, LinkedInLogoIcon } from "@radix-ui/react-icons";
import { Link } from "react-router-dom";
import { cn } from "../utils/utils";

function Footer(props: { className?: string }) {
  return (
    <footer
      className={cn("flex flex-col items-center py-12 gap-8 md:gap-4 px-2 md:px-[10%]", props.className)}
    >
      <hr className="w-full" />
      <div className="w-full flex flex-col md:flex-row justify-between gap-8">
        <h1>TONS OF MINDS</h1>
        <div className="flex flex-col md:flex-row gap-2 md:gap-6 text-sm md:hidden">
          <Link
            to="/sustainability"
            className="hover:text-blue-500"
          >
            Sustainability
          </Link>
          <Link
            to="/contact"
            className="hover:text-blue-500"
          >
            Contact
          </Link>
        </div>
        <div className="flex items-center gap-4">
          <Link
            className="group rounded-full p-1 md:p-2 border bg-transparent transition-colors border-[--primary] hover:bg-[--primary]"
            to="mailto:bonjour@tonsofminds.com"
            target="_blank"
          >
            <EnvelopeClosedIcon
              color="var(--primary)"
              className="group-hover:stroke-primary-foreground"
            />
          </Link>
          <Link
            className="group rounded-full p-1 md:p-2 border bg-transparent transition-colors border-[--primary] hover:bg-[--primary]"
            to="https://www.instagram.com/tonsofminds"
            target="_blank"
          >
            <InstagramLogoIcon
              color="var(--primary)"
              className="group-hover:stroke-primary-foreground"
            />
          </Link>
          <Link
            className="group rounded-full p-1 md:p-2 border bg-transparent transition-colors border-[--primary] hover:bg-[--primary]"
            to="https://www.linkedin.com/company/tons-of-minds/"
            target="_blank"
          >
            <LinkedInLogoIcon
              color="var(--primary)"
              className="group-hover:stroke-primary-foreground"
            />
          </Link>
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row md:justify-between gap-4">
        <div className="gap-4 text-sm hidden md:flex">
          <Link
            to="/sustainability"
            className="hover:text-blue-500"
          >
            Sustainability
          </Link>
          <Link
            to="/contact"
            className="hover:text-blue-500"
          >
            Contact
          </Link>
        </div>
        <div className="">
          © 2025
        </div>
      </div>
    </footer>
  );
}

export default Footer;
