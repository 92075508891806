import React, { useEffect, useState } from "react";

type SideNavigationProps = {
    refs: (HTMLDivElement | null)[];
};

const SideNavigation: React.FC<SideNavigationProps> = ({ refs }) => {
    const [activeSection, setActiveSection] = useState<number>(0);

    useEffect(() => {
        const handleScroll = () => {
            refs.forEach((ref, index) => {
                const rect = ref?.getBoundingClientRect();
                const scrollContainer = document.getElementById("main-container");
                if (rect && rect.top >= 0 && scrollContainer && rect.top < scrollContainer.clientHeight / 2) {
                    setActiveSection(index);
                }
            });
        };

        document.getElementById("main-container")?.addEventListener("scroll", handleScroll);
        return () => document.getElementById("main-container")?.removeEventListener("scroll", handleScroll);
    }, [refs]);

    const handleScrollToSection = (index: number) => {
        refs[index]?.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <nav className="fixed top-1/2 -translate-y-1/2 right-4 space-y-2 flex flex-col z-50">
            {refs.map((_, index) => (
                <button
                    key={index}
                    onClick={() => handleScrollToSection(index)}
                    className={`hidden md:block px-3 py-1 rounded-lg text-sm font-medium transition ${activeSection === index
                        ? "bg-primary text-white border border-blue-700 shadow-lg"
                        : "bg-gray-100 text-gray-800 border border-gray-300 hover:bg-gray-200"
                        }`}
                />
            ))}
        </nav>
    );
};

export default SideNavigation;
