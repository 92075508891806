"use client";
import { motion } from "framer-motion";
import { useState } from "react";
import useWindowSize from "../utils/hooks/useWindowSize";
import { Link } from "react-router-dom";
import TOMImage from "./ui/tomImage";

const nav = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const navLink = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  hover: {
    y: -3,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const navLogo = {
  open: {
    opacity: 1,
    transition: { delay: 0.5 },
  },
  closed: {
    opacity: 0,
  },
};

const Path = (props: any) => (
  <motion.path
    className="stroke-secondary-foreground"
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
);

const NavButton = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: "M 2 2 L 20 2" },
          open: { d: "M 3 16.5 L 17 2.5" },
        }}
      />
      <Path
        d="M 2 10 L 20 10"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: "M 2 18 L 20 18" },
          open: { d: "M 3 2.5 L 17 17" },
        }}
      />
    </svg>
  );
};

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const windowSize = useWindowSize(0, 1000);

  const sidebar = {
    open: () => ({
      clipPath: `circle(${windowSize.height * 2 + 200}px at ${windowSize.width - 40
        }px 40px)`,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 40,
      },
    }),
    closed: {
      clipPath: `circle(25px at ${windowSize.width - 40}px 40px)`,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 40,
      },
    },
  };

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      className="z-[51]"
    >
      <div className="hidden absolute md:flex justify-between items-center top-0 left-0 right-0 z-[52] px-8 lg:px-12 py-4 lg:py-6 text-white mix-blend-difference">
        <Link to="/">
          <img src="/images/TOM-LOGO.svg" alt="TOM-LOGO" className="h-5 w-auto" />
        </Link>
        <div className="flex gap-6 xl:gap-8 text-m">
          <Link to="/projects">PROJECTS</Link>
          <Link to="/join">CAREERS</Link>
          <Link to="/contact">CONTACTS</Link>
        </div>
      </div>
      <motion.div
        key={`w-${windowSize.width}`}
        className="fixed md:hidden top-0 right-0 bottom-0 w-full bg-secondary/95 z-[52]"
        variants={sidebar}
      />
      <motion.ul
        className="fixed top-32 left-[28.5px] [&>li]:mb-4 [&>li]:md:mb-6 font-semibold z-[53] text-secondary-foreground"
        variants={nav}
        hidden={!isOpen}
      >
        <motion.li variants={navLink} whileHover="hover">
          <Link to="/" onClick={() => setIsOpen(false)}>
            HOME
          </Link>
        </motion.li>

        <motion.li variants={navLink} whileHover="hover">
          <Link to="/projects" onClick={() => setIsOpen(false)}>
            PROJECTS
          </Link>
        </motion.li>
        <motion.li variants={navLink} whileHover="hover">
          <Link to="/join" onClick={() => setIsOpen(false)}>
            CAREERS
          </Link>
        </motion.li>
        <motion.li variants={navLink} whileHover="hover">
          <Link to="/contact" onClick={() => setIsOpen(false)}>
            CONTACTS
          </Link>
        </motion.li>
      </motion.ul>
      <TOMImage
        src="logo-gradient.png"
        alt="Tons of Minds Limited"
        variants={navLogo}
        hidden={!isOpen}
        className="fixed bottom-5 left-[20.5px] h-6 w-12 z-[53]"
      />
      <motion.button
        className="fixed md:hidden top-[20px] right-[20.5px] z-[53] p-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        <NavButton />
      </motion.button>
    </motion.nav>
  );
};

export default Nav;
