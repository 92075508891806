import { forwardRef, ReactNode } from "react";

const MovingBackgroundDiv = forwardRef<HTMLDivElement, {
  scroll: number;
  factor: number;
  children: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}>((props, ref) => {
  return (
    <div
      ref={ref}
      style={{
        ...props.style,
        backgroundPosition: ref && 'current' in ref && ref.current
          ? `50% ${Math.round(
              (ref.current.offsetTop - props.scroll) * props.factor
            )}px`
          : "50% 0",
      }}
      className={props.className}
    >
      {props.children}
    </div>
  );
});

export default MovingBackgroundDiv;
