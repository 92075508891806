import { Link } from "react-router-dom";
import { useState } from 'react';

const projects = [
  {
    title: "Digital Transformation",
    id: '9011',
  },
  {
    title: "XiongAn Residential",
    id: '9012',
  },
  {
    title: "Renotrust",
    id: '1000',
  },
  {
    title: "Fabrication",
    id: '9001',
  },
  {
    title: "Stem Education",
    id: '3001',
  },
  {
    title: "Fabrication",
    id: '9006',
  },
  {
    title: "City of Ripples",
    id: '9014',
  },
  {
    title: "Belts of Sustainability",
    id: '9016',
  },
  {
    title: "Journey of the Woods",
    id: '9017',
  },
  {
    title: "Virtual Home",
    id: '9018',
  },
  {
    title: "Ripples and Stones",
    id: '9019',
  },
  {
    title: "Village Centre",
    id: '9020',
  },
  {
    title: "Conservation Centre",
    id: '9021',
  },
  {
    title: "Future Transport",
    id: '9022',
  },
  {
    title: "Scuplture",
    id: '9023',
  },
  {
    title: "Diaolou Conservation",
    id: '9030',
  },
  {
    title: "Paper Boat",
    id: '9002',
  },
  {
    title: "Installation Art",
    id: '9032',
  },
  {
    title: "Installation Art",
    id: '9031',
  },
  {
    title: "Small House",
    id: '9034',
  },
  {
    title: "Planning",
    id: '9033',
  },
  {
    title: "Fititure",
    id: '1001',
  },
  {
    title: "AzurPet",
    id: '1002',
  },
  {
    title: "Silverse",
    id: '1003',
  },
  {
    title: "Happy Nuts",
    id: '1005',
  },
  {
    title: "Green Transportation Kai Tak",
    id: '2001',
  },
  {
    title: "Community Architects Initiative",
    id: '2002',
  },
  {
    title: "City Flyover Bridge",
    id: '9036',
  },
  {
    title: "City Gateway Landmark",
    id: '9037',
  },
  {
    title: "Household Well-being",
    id: '9039',
  },
  {
    title: "Cheng-Yu Sculpture",
    id: '9040',
  },
]

function ProjectsPage() {
  const [selectedCategory, setSelectedCategory] = useState<string>('All');

  const categories = ['All', 'Design', 'Technology', 'Community', 'Education'];

  const getCategoryText = (id: string | number): string => {
    const firstDigit = id.toString().charAt(0);
    switch (firstDigit) {
      case '9': return 'Design';
      case '1': return 'Technology';
      case '2': return 'Community';
      case '3': return 'Education';
      default: return '';
    }
  };

  const filteredProjects = selectedCategory === 'All'
    ? projects
    : projects.filter(project => getCategoryText(project.id) === selectedCategory);

  return (
    <div className="py-24 px-2 md:px-[10%] flex items-center justify-center">
      <div className="container mx-auto">
        <h1 className="text-2xl text-black font-medium mb-4">PROJECTS</h1>
        <div className="flex flex-wrap gap-2 mt-4">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`
                  px-4 py-2 rounded-full border border-gray-300 text-sm transition-all
                  ${selectedCategory === category
                  ? 'bg-black text-white'
                  : 'bg-transparent text-black hover:bg-black hover:text-white'
                }
                `}
            >
              {category}
            </button>
          ))}
        </div>

        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
          {filteredProjects.map((area, index) => {
            return (
              <Link
                to={`/project/${area.id}`}
                className="rounded max-h-[320px] md:max-h-[480px] aspect-square relative group border border-gray-300"
                key={`service-${index}`}
              >
                <div className="absolute inset-0 group-hover:opacity-0 transition-opacity duration-500">
                  <div className="text-xl text-black p-4">
                    <span className="block text-sm mb-2">{area.id}</span>
                    <span className="text-2xl font-bold block">{area.title}</span>
                  </div>
                  <div className="absolute bottom-4 left-4">
                    <div
                      className="text-white text-xs px-3 py-1 rounded-full"
                      style={{ backgroundColor: '#05299E' }}
                    >
                      {getCategoryText(area.id)}
                    </div>
                  </div>
                  <div className="absolute bottom-4 right-4 text-sm text-black">
                    Details
                  </div>
                </div>
                <img
                  className="rounded w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-500"
                  src={`/images/projects/${area.id}Hero2.jpg`}
                  alt={`project-${area.id}`}
                />
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ProjectsPage;