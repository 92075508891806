import { cn } from "@/src/utils/utils";

function DownArrowDiv(props: {
    className?: string;
    scrollRef?: React.RefObject<Element>
}) {
    return (
        <div
            className={cn(props.className, props.scrollRef ? 'cursor-pointer' : '')}
            onClick={() => { props.scrollRef?.current?.scrollIntoView({ behavior: "smooth", inline: "center" }) }}
        >
            <img src="/images/down-arrow.svg" width={32} alt="arrow" />
        </div>
    );
}

export default DownArrowDiv;
