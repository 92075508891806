import { useEffect, useRef, useState } from "react";
import MovingBackgroundDiv from "@/src/components/ui/movingBackgroundDiv";
import { cn } from "@/src/utils/utils";
import Footer from "@/src/components/footer";
import DownArrowDiv from "@/src/components/ui/downArrow";
import TOMImage from "@/src/components/ui/tomImage";
import { Link } from "react-router-dom";
import SideNavigation from "../components/ui/scrollHelper";

const areas = [
  {
    title: "MINDS empowering future.",
    image: "images/cover.jpg",
  },
  {
    title: "JOIN TECHNOLOGY INTERVENTION",
    image: "/images/projects/Minds3.jpg",
  },
  {
    title: "NURTURE THE NEXT GENERATION",
    image: "/images/projects/Minds4.jpg",
  },
  {
    title: "ART IS A LIFESTYLE",
    image: "/images/projects/Minds2.jpg",
  },
]

function HomePage(props: { yScroll?: number; yScrollProgress?: number }) {
  const texts = [
    "Curious",
    "Innovative",
    "Collaborative",
    "Dynamic",
    "Problem-Solving",
  ];
  const pointer = useRef([0, 1, 1]); // 0: texts[0], 1: texts[0][1], 1: Direction
  const textRef = useRef<HTMLDivElement>(null);
  const secondRef = useRef<HTMLDivElement | null>(null);
  const movingBackgroundRefs = useRef<(HTMLDivElement | null)[]>([]);
  const scrollRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [text, setText] = useState("");
  const [blink, setBlink] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      pointer.current[1] += pointer.current[2];
      if (pointer.current[1] > texts[pointer.current[0]].length + 6) {
        pointer.current[2] = -1;
      }
      if (pointer.current[1] < 0) {
        pointer.current[2] = 1;
        pointer.current[0] += 1;
      }
      if (pointer.current[0] >= texts.length) {
        pointer.current[0] = 0;
      }
      if (pointer.current[1] === texts[pointer.current[0]].length) {
        setBlink(!blink);
      }
      setText(texts[pointer.current[0]].substring(0, pointer.current[1] + 1));
    }, 100);
    return () => clearInterval(interval);
  });

  return (
    <>
      <SideNavigation refs={scrollRefs.current} />

      <div className="h-dvh relative flex justify-center items-center snap-start" ref={(el) => scrollRefs.current[0] = el}>
        <video
          className="absolute w-full h-dvh object-cover bg-clip-text bg-gradient-to-r from-accent-alt to-accent-foreground"
          autoPlay
          muted
          loop
        >
          <source src="1.mp4" type="video/mp4" />
        </video>
        <h1 className="text-center text-white bg-transparent p-3 md:p-5 text-4xl md:text-6xl lg:text-8xl font-semibold font-sans z-10">
          <span>{text}</span>
          <span className={cn("font-normal", blink ? "animate-blink" : "")}>
            |
          </span>
          <span className="ml-2 md:ml-4 lg:ml-6">MINDS</span>
        </h1>
        <div className="absolute bottom-4 left-4 text-white">Tons of Minds is a collective that unleashes creativity and nurtures innovative minds.</div>
        <DownArrowDiv
          className={
            cn("absolute bottom-10 left-[calc(50%-16px)] animate-bounce duration-1000 transition-opacity", props.yScroll && props.yScroll > 0 ? "opacity-0" : "")
          }
          scrollRef={secondRef}
        />
      </div>

      <div className="min-h-dvh py-16 px-8 snap-start" ref={(el) => {
        if (el) {
          secondRef.current = el;
          scrollRefs.current[1] = el;
        }
      }}>
        <div className="flex flex-col md:flex-row justify-center">
          <div className="md:w-4/5 lg:w-7/12 max-w-[800px]">
            <div className="mb-4" ref={textRef}>
              <h2 className="text-2xl">
                Innovation + Technology
              </h2>
              <div>Tons of Minds believes the power of innovation and the emotional value it brings to the human, we express our innovation through public artworks, metaverse, digital spaces, with technology as one of the driver. We believes the future is the era of innovation and passion.</div>
            </div>
            <img src="/images/sculpture-l.jpg" alt="sculpture" className="rounded-lg" />
            <div className="text-sm mt-2">The city installation “Flow” demonstrated the potential of AR augmented fabrication.</div>
            <div className="text-gray-700 text-sm">© Digital Lab, Faculty of Architecture | Huaqiao University</div>
          </div>
          <div className="md:pl-4 pt-4 md:pt-0 max-w-80">
            <div className="hidden md:block" style={{ height: textRef.current?.clientHeight ? textRef.current?.clientHeight + 16 : 0 }} />
            <img src="/images/sculpture-r.jpg" alt="sculpture" className="w-full max-h-[400] max-w-80 rounded-lg" />
            <div className="text-sm mt-2">We collaborate with public institutes and creatives to create public artworks.</div>
            <div className="text-gray-700 text-sm">© Joe Wong | Tons of Minds</div>
          </div>
        </div>
      </div>

      {areas.map(
        (area, index) => {
          return (
            <MovingBackgroundDiv
              scroll={props.yScroll || 0}
              factor={0.5}
              style={{
                backgroundImage: `url(${area.image})`,
              }}
              key={`area-${index}`}
              ref={(el) => {
                if (el) {
                  movingBackgroundRefs.current[index] = el;
                  scrollRefs.current[index + 2] = el;
                }
              }
              }
              className="font-bold bg-fixed bg-cover bg-no-repeat full-screen text-2xl md:text-4xl lg:text-6xl flex items-center justify-center snap-start"
            >
              <span className="text-shadow text-white text-center text-xl md:text-3xl lg:text-6xl">{area.title}</span>
            </MovingBackgroundDiv>
          );
        }
      )}

      <div className="pt-8 px-4">
        <h2 className="text-black text-xl md:text-2xl mb-6">
          Minds
        </h2>
        <Link
          className="flex gap-2 w-fit"
          to={"https://www.instagram.com/tonsofminds/"}
          target="_blank"
        >
          <TOMImage
            src="logo192.png"
            alt="Tons of Minds Limited"
            className="h-10 w-10 "
          />
          <div className="text-sm">
            <div className="font-bold">tonsofminds</div>
            <div className="font-medium">MINDS EMPOWERING FUTURE</div>
          </div>
        </Link>
      </div>
      <Footer className="snap-start" />
    </>
  );
}

export default HomePage;
